import React, { useState } from 'react';
import { InternationalContext } from '../../InternationalContext';
import { FormattedMessage } from 'react-intl';
import { Link, NavLink } from 'react-router-dom';
import DataCenter from '../data/DataCenter';
import { map } from 'lodash';

type Props = {
  menuItems: MenuItem[];
  cartCount: number;
};

const NavigationComponent = ({ menuItems, cartCount }: Props) => {
  const [activeIndex, setActiveIndex] = useState<number | undefined>(0);
  const [displayMenu, setDisplayMenu] = useState(false);

  const { switchToEnglish, switchToGerman, switchToChinese, locale } =
    React.useContext(InternationalContext);

  const closeMenu = (anchorName?: string, index?: number) => {
    setDisplayMenu((displayMenu: boolean) => !displayMenu);
    if (anchorName) {
      const anchorElement = document.getElementById(anchorName);
      if (anchorElement) {
        setActiveIndex(index);
        anchorElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  };

  const openMenuFile = () => {
    window.open(DataCenter.LUNCH_MENU_PATH);
  };

  const LanguageSwitchHook = () => {
    const languageItemSettings: {
      [key: string]: { clickFunction?: VoidFunction; word: string };
    } = {
      de: { clickFunction: switchToEnglish, word: '(In English)' },
      en: { clickFunction: switchToChinese, word: '(转换中文)' },
      zh: { clickFunction: switchToGerman, word: '(Auf Deutsch)' },
    };
    return (
      <>
        {locale && (
          <Link
            className="white"
            to={'#'}
            onClick={languageItemSettings[locale].clickFunction}
          >
            {languageItemSettings[locale].word}
          </Link>
        )}
      </>
    );
  };

  const items = map(menuItems, (it: MenuItem, key: number) => {
    const navElement = (
      <NavLink
        className={() => (key === activeIndex ? 'navactive' : '')}
        to={it.target}
        onClick={
          it.navigateToMenu
            ? () => openMenuFile()
            : () => closeMenu(it.linkPath, key)
        }
      >
        <FormattedMessage id={it.key} />
      </NavLink>
    );
    const cartElement = (
      <span className="white">
        <Link className="fa fa-shopping-cart white" to="/cart">
          &nbsp;<span className="white badge">{cartCount}</span>
        </Link>
      </span>
    );
    return (
      <li key={key} className={'color_animation'}>
        {it.show && (it.navigateToCart ? cartElement : navElement)}
      </li>
    );
  });

  return (
    <nav className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="row">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              onClick={() => closeMenu()}
            >
              <span className="sr-only" />
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </button>
            <p className="navbar-brand">
              <FormattedMessage id="app.restaurant.name" />
            </p>
          </div>
          <div
            className="collapse navbar-collapse"
            style={{ display: displayMenu ? 'block' : '' }}
          >
            <ul className="nav main-nav  clear navbar-right ">
              {items}
              <li key={items.length + 1} style={{ margin: '0 -22px' }}>
                <LanguageSwitchHook />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavigationComponent;
