import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import CartService from '../service/CartService';
import DataCenter from '../data/DataCenter';
import BoxItem from '../base/BoxItemComponent';
import { InternationalContext } from '../../InternationalContext';
import { loadCategories } from '../service/UserService';
import { map, chain } from 'lodash';

type Props = {
  updateCart: () => void;
  orderEnable: boolean;
};

const SpecialityComponent = ({ updateCart, orderEnable }: Props) => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [foods, setFoods] = useState<SpecialityItem[]>(DataCenter.FOOD_ITEMS);

  const { locale } = useContext(InternationalContext);

  useEffect(() => {
    loadCategories().then((data: Category[]) => {
      if (data.length) {
        data[0].selected = true;
      }
      setCategories(data);
    });
  }, []);

  const filterFood = (event: any) => {
    const updateCategories = map(categories, (it: Category) => {
      it.selected = it.value === event.target.dataset.id;
      return it;
    });

    const updateFoods = map(foods, (it: SpecialityItem) => {
      it.show = it.categories.indexOf(event.target.dataset.id) > -1;
      return it;
    });

    setCategories(updateCategories);
    setFoods(updateFoods);
  };

  const addToCart = (item: SpecialityItem) => {
    CartService.addToCart(item);
    item.addedToCart = true;
    updateCart();
  };

  const foodItems = map(foods, (it: SpecialityItem, key: number) => {
    return (
      <div
        className="item"
        key={key}
        //style is to fix android galaxy mobile
        style={{ position: 'relative', display: it.show ? '' : 'none' }}
      >
        <BoxItem
          item={it}
          language={locale}
          addToCart={addToCart}
          orderEnable={orderEnable}
        />
      </div>
    );
  });

  const categoryItems = chain(categories)
    .filter((it: Category) => it.show)
    .map((it: Category, key: number) => (
      <li key={key} className={it.selected ? 'active' : ''} data-id={it.value}>
        <FormattedMessage id={'app.menu.' + it.name} />
      </li>
    ))
    .value();

  return (
    <section id="speciality" className="description_content">
      <div className="text-content container">
        <div className="container">
          <div className="row">
            <div>
              <ul
                className="filter-list clearfix"
                onClick={(event) => filterFood(event)}
              >
                {categoryItems}
              </ul>
              <div className="portfolio">{foodItems}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SpecialityComponent;
