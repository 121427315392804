import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import React from 'react';
import DataCenter from '../data/DataCenter';
import CartService from '../service/CartService';

type Props = {
  item: SpecialityItem;
  language?: string;
  addToCart: Function;
  orderEnable: boolean;
};

const BoxItem = ({ item, language, addToCart, orderEnable }: Props) => {
  let name = item.nameDe;
  let description = item.descriptionDe;
  if (language && language.startsWith(DataCenter.LANGUAGE.EN, 0)) {
    name = item.nameEn;
    description = item.descriptionEn;
  } else if (language && language.startsWith(DataCenter.LANGUAGE.ZH, 0)) {
    name = item.nameZn;
    description = item.descriptionZn;
  }

  let hotDegree = '';
  if (item.hot) {
    for (let i = 0; i < item.hot; i++) {
      hotDegree = hotDegree.concat('🌶');
    }
  }

  return (
    <>
      <div>
        <img src={item.imgSrc} alt={name} loading="lazy" />
        <div className="price">
          {item.addedToCart && (
            <div className="alert alert-success small-text">
              <strong>
                <FormattedMessage id="app.cart.add.ordered" />
              </strong>
            </div>
          )}
          <span>{CartService.formatCurrency(item.price, language)}</span>
          {orderEnable && (
            <button className="btn btn-primary" onClick={() => addToCart(item)}>
              <FormattedMessage id="app.cart.add" />
              &nbsp;&nbsp;
              <Link className="fa fa-shopping-cart" to="#" />
            </button>
          )}
        </div>
      </div>
      <div>
        <h2>
          {item.id}.{name}
        </h2>
        <span>{hotDegree}</span>
        <span>{description}</span>
        <span>{CartService.formatCurrency(item.price, language)}</span>
      </div>
    </>
  );
};

export default BoxItem;
