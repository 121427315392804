import { concat, each, filter, find, map, remove, some } from 'lodash';
import DataCenter from '../../data/DataCenter';
import CartService from '../../service/CartService';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import InputField from '../../base/InputField';
import HeaderMainFooterLayout from '../../base/HeaderMainFooterLayout';
import NavigationComponent from '../NavigationComponent';
import FooterComponent from '../FooterComponent';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { InternationalContext } from '../../../InternationalContext';
import { v4 as uuidv4 } from 'uuid';

type CartProps = {
  orderReference: string;
  menuItems: MenuItem[];
  configuration: Configuration;
  initialOrders: OrderItem[];
  initialCustomer: Customer;
};

export const ShopCart = ({
  orderReference,
  menuItems,
  configuration,
  initialOrders,
  initialCustomer,
}: CartProps) => {
  const intl = useIntl();
  const { locale } = useContext(InternationalContext);
  const [orders, setOrders] = useState<OrderItem[]>(initialOrders);
  const [customer, setCustomer] = useState<Customer>(initialCustomer);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isEmailRequired, setIsEmailRequired] = useState<boolean>(false);
  const [isOrderSent, setIsOrderSent] = useState<boolean>(false);
  const [isSendOrderButtonDisable, setIsSendOrderButtonDisable] =
    useState<boolean>(false);

  const isValid = useCallback(() => {
    const errors: any = {};
    //Name
    if (!customer.name) {
      errors.name = 'Not Empty';
    }
    //Email
    if (customer.email) {
      const lastAtPos = customer.email.lastIndexOf('@');
      const lastDotPos = customer.email.lastIndexOf('.');
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          customer.email.indexOf('@@') === -1 &&
          lastDotPos > 2 &&
          customer.email.length - lastDotPos > 2
        )
      ) {
        errors.email = 'Invalid Email';
      }
    }
    //Telephone
    if (!customer.telephone) {
      errors.telephone = 'Not Empty';
    }
    const { price } = CartService.sumWithDeliveryFee(orders, customer.postal);
    //Price
    if (!price) {
      errors.price = 'Price less than 0 Euro';
    }
    return errors;
  }, [customer, orders]);

  useEffect(() => {
    const result = isValid();
    if (isEmailRequired && !customer.email) {
      result['email'] = 'Not Empty';
    }
    setErrors(result);
    CartService.updateOrders(orders);
    CartService.updateCustomer(customer);
  }, [customer, orders, isEmailRequired, isValid]);

  const handleOrderInformation = (it: OrderItem) => {
    let name = it.nameDe;
    let description = it.descriptionDe;
    if (locale === DataCenter.LANGUAGE.EN) {
      name = it.nameEn;
      description = it.descriptionEn;
    } else if (locale === DataCenter.LANGUAGE.ZH) {
      name = it.nameZn;
      description = it.descriptionZn;
    }
    return {
      name,
      description,
    };
  };

  const handleQuantityChange = (field: OrderItem, e: any) => {
    const currentOrders = [...orders];
    const found = find(currentOrders, (it: OrderItem) => it.id === field.id);
    if (found) {
      found.quantity = e.target.value;
      setOrders(currentOrders);
    }
  };

  const enterMessages = (e: any) => {
    const currentCustomer: any = { ...customer };
    currentCustomer.message = e.target.value;
    setCustomer(currentCustomer);
  };

  const handleFieldValueChange = (field: string, value: string | number) => {
    const currentCustomer: any = { ...customer };
    currentCustomer[field] = value;
    setCustomer(currentCustomer);
  };

  const removeOrder = (item: OrderItem) => {
    const currentOrders = [...orders];
    remove(currentOrders, (it: OrderItem) => it.id === item.id);
    setOrders(currentOrders);
  };

  const sendOrder = () => {
    setIsSendOrderButtonDisable(true);
    const { price, deliveryFee, selfTaken } = CartService.sumWithDeliveryFee(
      orders,
      customer.postal
    );
    const total = price + (deliveryFee ?? 0);
    each(orders, (it: OrderItem) => (it.total = it.price * it.quantity));

    const deliveryFeeContact = selfTaken
      ? intl.formatMessage({ id: 'app.cart.menu.selfTaken' })
      : !selfTaken && !deliveryFee
      ? intl.formatMessage(
          { id: 'app.cart.menu.deliveryFeeContact' },
          { phone: configuration.telephone }
        )
      : undefined;

    const cart: Cart = {
      id: uuidv4(),
      orderReference: orderReference,
      customer: customer,
      price: price,
      deliveryFee: deliveryFee,
      deliveryFeeContact: deliveryFeeContact,
      total: total,
      isEmailRequired: isEmailRequired,
      orders: orders,
    };
    CartService.sendCart(cart)
      .finally(() => {
        setIsOrderSent(true);
        setIsSendOrderButtonDisable(false);
      })
      .catch((result: string) => {
        errors['result'] = result || 'errors';
        setErrors(errors);
      });
  };

  const close = () => {
    window.location.reload();
  };

  const isOrderValid = () => {
    return !!Object.keys(errors).length;
  };

  const changeAdditionRice = (event: { target: { checked: boolean } }) => {
    const withRice = event.target.checked;
    const rice = DataCenter.RICE_ITEM;
    let currentOrders = [...orders];
    if (withRice) {
      currentOrders.push(rice);
    } else {
      currentOrders = filter(
        currentOrders,
        (it: OrderItem) => it.id !== rice.id
      );
    }
    setOrders(currentOrders);
  };

  const requireEmailConfirmation = (event: {
    target: { checked: boolean };
  }) => {
    setIsEmailRequired(event.target.checked);
  };

  const { price, deliveryFee, selfTaken } = CartService.sumWithDeliveryFee(
    orders,
    customer.postal
  );
  const withRice = some(
    orders,
    (order: OrderItem) => order.id === DataCenter.RICE_ITEM.id
  );
  const ordersDetail = map(orders, (it: OrderItem, key: number) => {
    const { name, description } = handleOrderInformation(it);

    return (
      <tr key={key}>
        <td>{it.id}</td>
        <td>{name}</td>
        <td>{description}</td>
        <td>{it.price}</td>
        <td>
          <input
            type="number"
            min={1}
            size={2}
            style={{ width: '60px' }}
            className="form"
            onChange={(event) => handleQuantityChange(it, event)}
            value={it.quantity}
          />
        </td>
        <td>{CartService.formatCurrency(it.price * it.quantity, locale)}</td>
        <td>
          <Link to="#" onClick={() => removeOrder(it)}>
            <span className="glyphicon glyphicon-minus" aria-hidden="true" />
          </Link>
        </td>
      </tr>
    );
  });

  const orderModal = map(orders, (it: OrderItem, key: number) => {
    const { name } = handleOrderInformation(it);
    return (
      <tr key={key}>
        <td>{name}</td>
        <td>{it.price}</td>
        <td>{it.quantity}</td>
        <td>{CartService.formatCurrency(it.price * it.quantity, locale)}</td>
      </tr>
    );
  });

  const mainComponent: JSX.Element = (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>id</th>
                    <th>
                      <FormattedMessage
                        id="app.cart.menu.name"
                        defaultMessage="Name"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="app.cart.menu.description"
                        defaultMessage="Description"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="app.cart.menu.price"
                        defaultMessage="Price"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="app.cart.menu.quantity"
                        defaultMessage="Quantity"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="app.cart.menu.total"
                        defaultMessage="Total"
                      />
                    </th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {ordersDetail}
                  <tr>
                    <td colSpan={7}>
                      <div className="form-check right">
                        <div className="checkbox">
                          <label>
                            <input
                              type="checkbox"
                              checked={withRice}
                              onChange={changeAdditionRice}
                            />
                            <FormattedMessage
                              id="app.cart.addRice"
                              defaultMessage="Total"
                            />
                          </label>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={7}>
                      <span className="right">
                        <FormattedMessage
                          id="app.cart.menu.total"
                          defaultMessage="Total"
                        />
                        &nbsp;:&nbsp;
                        {CartService.formatCurrency(price, locale)}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    {!selfTaken && deliveryFee && (
                      <td colSpan={7}>
                        <span className="right">
                          <FormattedMessage
                            id="app.cart.menu.deliveryFee"
                            defaultMessage="Total"
                          />
                          &nbsp;:&nbsp;
                          {CartService.formatCurrency(deliveryFee, locale)}
                        </span>
                      </td>
                    )}
                    {selfTaken && (
                      <td colSpan={7}>
                        <span className="right">
                          <strong>
                            <FormattedMessage
                              id="app.cart.menu.selfTaken"
                              defaultMessage="Total"
                            />
                          </strong>
                        </span>
                      </td>
                    )}
                  </tr>
                  {deliveryFee === undefined && (
                    <tr>
                      <td colSpan={7}>
                        <span className="right list-group-item list-group-item-info">
                          <strong>
                            <FormattedMessage
                              id="app.cart.menu.deliveryFeeContact"
                              defaultMessage="Total"
                              values={{ phone: configuration.telephone }}
                            />
                          </strong>
                        </span>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td colSpan={7}>
                      {!!deliveryFee && (
                        <span className="right">
                          <FormattedMessage
                            id="app.cart.menu.total.all"
                            defaultMessage="Total"
                          />
                          &nbsp;:&nbsp;
                          {CartService.formatCurrency(
                            price + deliveryFee,
                            locale
                          )}
                        </span>
                      )}
                      {!deliveryFee && (
                        <span className="right">
                          <FormattedMessage
                            id="app.cart.menu.total.all"
                            defaultMessage="Total"
                          />
                          &nbsp;:&nbsp;
                          {CartService.formatCurrency(price, locale)}
                        </span>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="line" />
            <div className="inner">
              <div className="contact-form contact">
                <form onSubmit={(event) => event.preventDefault()}>
                  <div className="col-md-6 ">
                    <InputField
                      item={{
                        id: 'app.contact.name',
                        type: 'text',
                        defaultMessage: 'Name',
                        value: customer.name,
                        field: 'name',
                        className: 'form',
                        error: errors['name'],
                      }}
                      onChange={handleFieldValueChange}
                    />
                    <InputField
                      item={{
                        id: 'app.contact.address',
                        type: 'text',
                        defaultMessage: 'Address',
                        value: customer.address,
                        field: 'address',
                        className: 'form',
                        error: errors['address'],
                      }}
                      onChange={handleFieldValueChange}
                    />
                    <InputField
                      item={{
                        id: 'app.contact.email',
                        type: 'text',
                        defaultMessage: 'Email',
                        value: customer.email,
                        field: 'email',
                        className: 'form',
                        error: errors['email'],
                      }}
                      onChange={handleFieldValueChange}
                    />
                    <div className="row">
                      <div className="col-md-4">
                        <InputField
                          item={{
                            id: 'app.contact.postal',
                            type: 'text',
                            defaultMessage: 'Postal',
                            value: customer.postal,
                            field: 'postal',
                            className: 'form',
                            error: errors['postal'],
                          }}
                          onChange={handleFieldValueChange}
                        />
                      </div>
                      <div className="col-md-8">
                        <InputField
                          item={{
                            id: 'app.contact.city',
                            type: 'text',
                            defaultMessage: 'City',
                            value: customer.city,
                            field: 'city',
                            className: 'form',
                            error: errors['city'],
                            disabled: true,
                          }}
                          onChange={handleFieldValueChange}
                        />
                      </div>
                    </div>
                    <InputField
                      item={{
                        id: 'app.contact.telephone',
                        type: 'text',
                        defaultMessage: 'Telephone',
                        value: customer.telephone,
                        field: 'telephone',
                        className: 'form',
                        error: errors['telephone'],
                      }}
                      onChange={handleFieldValueChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormattedMessage
                      id="app.contact.wish"
                      defaultMessage="wish"
                    >
                      {(placeholder) => (
                        <textarea
                          name="message"
                          className="form textarea"
                          value={customer.message}
                          onChange={(event) => enterMessages(event)}
                          placeholder={placeholder.toString()}
                          style={{
                            borderColor: errors['message'] ? 'red' : '',
                          }}
                        />
                      )}
                    </FormattedMessage>
                  </div>
                  <div className="relative fullwidth col-xs-12">
                    <button
                      type="submit"
                      style={{
                        color:
                          isOrderValid() || configuration.emailDisable
                            ? '#808080'
                            : '',
                      }}
                      disabled={isOrderValid() || configuration.emailDisable}
                      className="form-btn"
                      data-toggle="modal"
                      data-target="#orderConfirm"
                    >
                      <FormattedMessage id="app.cart.confirm" />
                    </button>
                  </div>
                  <div className="clear" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="orderConfirm"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="exampleModalLabel">
                <FormattedMessage id="app.cart.confirm.title" />
              </h3>
              <h5>id-No.:{orderReference}</h5>
              <h4>{customer.name}</h4>
              <h5>{customer.email}</h5>
            </div>
            <div className="modal-body">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage
                        id="app.cart.menu.name"
                        defaultMessage="Name"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="app.cart.menu.price"
                        defaultMessage="Price"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="app.cart.menu.quantity"
                        defaultMessage="Quantity"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="app.cart.menu.total"
                        defaultMessage="Total"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {orderModal}
                  <tr>
                    <td colSpan={4}>
                      <span className="right">
                        <FormattedMessage
                          id="app.cart.menu.total"
                          defaultMessage="Total"
                        />
                        &nbsp;:&nbsp;
                        {CartService.formatCurrency(price, locale)}
                      </span>
                    </td>
                  </tr>
                  {!!deliveryFee && (
                    <tr>
                      <td colSpan={4}>
                        <span className="right">
                          <FormattedMessage
                            id="app.cart.menu.deliveryFee"
                            defaultMessage="Total"
                          />
                          &nbsp;:&nbsp;
                          {CartService.formatCurrency(deliveryFee, locale)}
                        </span>
                      </td>
                    </tr>
                  )}
                  {deliveryFee === undefined && (
                    <tr>
                      <td colSpan={7}>
                        <span className="right list-group-item list-group-item-info">
                          <strong>
                            <FormattedMessage
                              id="app.cart.menu.deliveryFeeContact"
                              defaultMessage="Total"
                              values={{ phone: configuration.telephone }}
                            />
                          </strong>
                        </span>
                      </td>
                    </tr>
                  )}
                  {selfTaken && (
                    <tr>
                      <td colSpan={4}>
                        <span className="right">
                          <strong>
                            <FormattedMessage
                              id="app.cart.menu.selfTaken"
                              defaultMessage="Total"
                            />
                          </strong>
                        </span>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td colSpan={7}>
                      {!!deliveryFee && (
                        <span className="right">
                          <FormattedMessage
                            id="app.cart.menu.total.all"
                            defaultMessage="Total"
                          />
                          &nbsp;:&nbsp;
                          {CartService.formatCurrency(
                            price + deliveryFee,
                            locale
                          )}
                        </span>
                      )}
                      {!deliveryFee && (
                        <span className="right">
                          <FormattedMessage
                            id="app.cart.menu.total.all"
                            defaultMessage="Total"
                          />
                          &nbsp;:&nbsp;
                          {CartService.formatCurrency(price, locale)}
                        </span>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>
                <FormattedMessage
                  id="app.contact.address"
                  defaultMessage="address"
                />
                :&nbsp;
                {concat(
                  customer.address,
                  ', ',
                  customer.postal,
                  ', ',
                  customer.city
                )}
              </p>
              <p className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    checked={isEmailRequired}
                    onChange={requireEmailConfirmation}
                  />
                  <FormattedMessage
                    id="app.cart.emailConfirm"
                    defaultMessage="emailConfirmation"
                  />
                </label>
              </p>
              {isOrderSent && (
                <p className="alert alert-success" role="alert">
                  <FormattedMessage id="app.cart.success" />
                </p>
              )}
              {errors['result'] && (
                <p className="alert alert-danger" role="alert">
                  <FormattedMessage id="app.cart.error" />
                </p>
              )}
            </div>
            <div className="modal-footer">
              {!isOrderSent && (
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  <FormattedMessage id="app.cart.cancel" />
                </button>
              )}
              {!isOrderSent && (
                <button
                  style={{
                    color:
                      isOrderValid() || isSendOrderButtonDisable
                        ? '#808080'
                        : '',
                  }}
                  disabled={isOrderValid() || isSendOrderButtonDisable}
                  type="button"
                  className="btn btn-primary"
                  onClick={sendOrder}
                >
                  <FormattedMessage id="app.cart.confirm" />
                </button>
              )}
              {isOrderSent && (
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={close}
                >
                  <FormattedMessage id="app.contact.closeButton" />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
  return (
    <HeaderMainFooterLayout
      header={
        <NavigationComponent menuItems={menuItems} cartCount={orders.length} />
      }
      main={
        <section id="cart" className="cart">
          {mainComponent}
        </section>
      }
      footer={<FooterComponent />}
    />
  );
};
