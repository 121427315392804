import { FormattedMessage } from 'react-intl';
import React from 'react';

type Props = {
  item: any;
  onChange: Function;
};

const InputField = ({ item, onChange }: Props) => {
  const setInputValue = (event: { target: { value: any } }) => {
    if (onChange) {
      onChange(item.field, event.target.value);
    }
  };

  return (
    <FormattedMessage id={item.id} defaultMessage={item.defaultMessage}>
      {(placeholder) => (
        <input
          type={item.type}
          className={item.className}
          onChange={setInputValue}
          value={item.value}
          placeholder={placeholder.toString()}
          style={{ borderColor: item.error ? 'red' : '' }}
          disabled={item.disabled}
        />
      )}
    </FormattedMessage>
  );
};

export default InputField;
