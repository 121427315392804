import React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Navigate } from 'react-router-dom';
import LOGO from './admin/svg/logo.svg';
import '../../css/Login.css';

const LoginComponent = () => {
  const { keycloak } = useKeycloak();

  if (keycloak.authenticated) {
    return <Navigate to={{ pathname: '/admin' }} />;
  }

  return (
    <div className="App-header">
      <h2>欢迎登陆后台管理系统</h2>
      <img src={LOGO} className="App-logo" alt="Logo" />
      <button className="btn btn-primary" onClick={() => keycloak.login()}>
        登录
      </button>
    </div>
  );
};

export default LoginComponent;
