import React from 'react';
import { ShopCart } from './ShopCart';
import CartService from '../../service/CartService';

type CartWrapperProps = {
  menuItems: MenuItem[];
  configuration: Configuration;
};
const ShopCartWrapper = ({ menuItems, configuration }: CartWrapperProps) => {
  return (
    <ShopCart
      orderReference={(Math.random() * 10e16).toString(36)}
      menuItems={menuItems}
      configuration={configuration}
      initialOrders={CartService.loadCart()}
      initialCustomer={CartService.loadCustomer()}
    />
  );
};
export default ShopCartWrapper;
