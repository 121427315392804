import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import DataCenter from '../data/DataCenter';
import { InternationalContext } from '../../InternationalContext';
import { chain, map } from 'lodash';

const HomeComponent = ({ configuration }: { configuration: Configuration }) => {
  const { locale } = useContext(InternationalContext);

  const workHourItems = map(configuration.workHours, (it: WorkHour) => (
    <p key={it.id}>
      <span>
        <FormattedMessage id={'app.home.openHours.' + it.dayStart} />
      </span>
      <span
        style={{
          display:
            it.dayStart === 'sunday' || it.dayStart === 'saturday'
              ? 'none'
              : '',
        }}
      >
        &nbsp;
        <FormattedMessage id="app.home.openHours.to" />
        &nbsp;
        <FormattedMessage id={'app.home.openHours.' + it.dayEnd} />
      </span>
      <span>
        {' '}
        : {it.startTime} - {it.endTime}
      </span>
    </p>
  ));

  const activityImage =
    configuration.document && configuration.document.filename ? (
      <div>
        <img
          style={{ width: '50%' }}
          src={configuration.document.path}
          alt={configuration.document.filename}
        />
      </div>
    ) : (
      <></>
    );

  const setAnnouncement = (language?: string) => {
    switch (language) {
      case DataCenter.LANGUAGE.EN:
        return configuration.announcementEn;
      case DataCenter.LANGUAGE.ZH:
        return configuration.announcementZn;
      default:
        return configuration.announcementDe;
    }
  };

  const announcement = setAnnouncement(locale);
  const announcements = chain(announcement.split('\n'))
    .map((it: string[], key: number) => (
      <span key={key}>
        {it}
        <br />
      </span>
    ))
    .value();

  return (
    <section id="home">
      <div className="text-content container follow_container">
        <div className="row small-padding">
          <div className="col-md-4">
            <div className="white right-text">
              <h2>
                <FormattedMessage id="app.home.announce" />
              </h2>
              <p>{announcements}</p>
            </div>
            <div className="home_mark right-text">
              <span>
                <FormattedMessage id="app.home.mark" />
              </span>
            </div>
            <div>{activityImage}</div>
          </div>
          <div className="col-md-4">
            <img
              className="wrapper"
              src={'/images/background.png'}
              alt="background"
            />
          </div>
          <div className="col-md-4">
            <div className="white right-text">
              <h2>
                <FormattedMessage id="app.home.openHours" />
              </h2>
              {workHourItems}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeComponent;
