import React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Navigate, useLocation } from 'react-router-dom';
import { Admin } from './Admin';

type AdminWrapperProps = {
  configuration: Configuration;
};
export const AdminWrapper = ({ configuration }: AdminWrapperProps) => {
  const { keycloak } = useKeycloak();
  const location = useLocation();

  if (!keycloak.authenticated) {
    return <Navigate to={{ pathname: '/login' }} state={{ from: location }} />;
  }

  return <Admin initialConfiguration={configuration} />;
};
