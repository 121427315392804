import React from 'react';
import { FormattedMessage } from 'react-intl';
import ProductVideo from '../base/ProductVideo';

const MediaComponent = () => {
  const videoJsOptions = {
    sources: [
      {
        src: 'https://media.w3.org/2010/05/sintel/trailer_hd.mp4',
        type: 'video/mp4',
      },
    ],
  };

  return (
    <section id="media" className="description_content">
      <div className="text-content container transparent">
        <div className="col-md-1" />
        <div className="col-md-10">
          <h1>
            <FormattedMessage id="app.media.cook.style" />
          </h1>
          <div>
            <ProductVideo options={videoJsOptions} />
          </div>
        </div>
        <div className="col-md-1" />
      </div>
    </section>
  );
};

export default MediaComponent;
