import Keycloak from 'keycloak-js';
import axios from 'axios';

const keycloak = new Keycloak({
  realm: import.meta.env.VITE_ERP_REALM || '',
  url: import.meta.env.VITE_KEYCLOAK_URL,
  clientId: import.meta.env.VITE_KEYCLOAK_CLIENT_ID || '',
});

const axiosInstance = (token?: string) => {
  const headers: any = {
    'Content-Type': 'application/json',
    'X-ErpRealm-Header': import.meta.env.VITE_ERP_REALM,
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return axios.create({
    baseURL: import.meta.env.VITE_SERVER_URL || '',
    headers: headers,
  });
};

const sendCartWithOrder = (cart: Cart) => {
  return dataPost('/api/cart', cart);
};

const getCart = (token?: string) => {
  return axiosInstance(token).get('/api/cart');
};

const sendEmail = (email: Email) => {
  return dataPost('/api/email', email);
};

const getConfiguration = () => {
  return axiosInstance().get('/api');
};

const getMenus = () => {
  return axiosInstance().get('/api/menu');
};

const getCategories = () => {
  return axiosInstance().get('/api/category');
};

const sendConfiguration = (management: Management, token?: string) => {
  return dataPost('/api/admin', management, token);
};

const getEmailConfiguration = (token?: string) => {
  return axiosInstance(token).get('/api/admin/emailConfiguration');
};

const sendEmailConfiguration = (
  emailConfiguration: EmailConfiguration,
  token?: string
) => {
  return dataPost('/api/admin/emailConfiguration', emailConfiguration, token);
};

const sendDocument = (data: FormData, token?: string) => {
  return dataPost('/api/admin/uploadFile', data, token);
};

const deleteAllDocuments = (token?: string) => {
  return axiosInstance(token)
    .options('/api/admin/deleteFiles', { withCredentials: true })
    .then(() =>
      axiosInstance(token).delete('/api/admin/deleteFiles', {
        withCredentials: true,
      })
    );
};

const dataPost = (path: string, data: any, token?: string) => {
  const instance = axiosInstance(token);
  return instance
    .options(path, { withCredentials: true })
    .then(() => instance.post(path, data, { withCredentials: true }));
};

export {
  keycloak,
  getConfiguration,
  sendCartWithOrder,
  getCart,
  getMenus,
  getCategories,
  sendEmail,
  sendConfiguration,
  getEmailConfiguration,
  sendEmailConfiguration,
  sendDocument,
  deleteAllDocuments,
};
