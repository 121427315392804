import React, { StrictMode, useEffect, useState } from 'react';
import { InternationalTranslationProvider } from './InternationalContext';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import MainPageComponent from './modules/components/MainPageComponent';
import './css/responsive.css';
import DataCenter from './modules/data/DataCenter';
import { loadInfo, loadMenus } from './modules/service/UserService';
import { keycloak } from './modules/service/ApiService';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import ShopCartWrapper from './modules/components/cart/ShopCartWrapper';
import {
  AuthClientError,
  AuthClientEvent,
  AuthClientTokens,
} from '@react-keycloak/core/lib/types';
import LoginComponent from './modules/components/LoginComponent';
import { AdminWrapper } from './modules/components/admin/AdminWrapper';
import { each, filter } from 'lodash';
import { withSentryReactRouterV6Routing } from '@sentry/react';

const Content = () => {
  const [configuration, setConfiguration] = useState<Configuration>(
    DataCenter.DEFAULT_CONFIGURATION
  );
  const [menus, setMenus] = useState<MenuItem[]>([]);
  const [cartMenus, setCartMenus] = useState<MenuItem[] | undefined>([]);
  const [orderEnable, setOrderEnable] = useState(false);

  useEffect(() => {
    loadInfo().then((data: Configuration) => setConfiguration(data));
    loadMenus().then((data: MenuItem[]) => {
      each(data, (it: MenuItem) => {
        it.navigateToMenu = it.show && it.key === DataCenter.LUNCH_MENU;
        it.navigateToCart =
          it.show &&
          it.key === DataCenter.CART_MENU &&
          it.key !== DataCenter.HOME_MENU;
        it.target = it.show && it.key === DataCenter.HOME_MENU ? '/' : '#';
        if (it.navigateToCart) {
          setOrderEnable(true);
        }
      });
      const cartMenuItems = filter(data, (it: MenuItem) => it.usedInCart);
      setCartMenus(cartMenuItems);
      setMenus(data || []);
    });
  }, []);

  const onAuthenticateStateUpdate = (
    event: AuthClientEvent,
    error?: AuthClientError
  ) => {
    console.log('onAuthenticateStateUpdate', event, error);
  };

  const onAuthenticateToken = (tokens: AuthClientTokens) => {
    console.log('onAuthenticateRefreshToken', tokens);
  };

  const SentryRoutes = withSentryReactRouterV6Routing(Routes);

  return (
    <StrictMode>
      <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={{ onLoad: 'check-sso' }}
        onEvent={onAuthenticateStateUpdate}
        onTokens={(token) => onAuthenticateToken(token)}
      >
        <InternationalTranslationProvider>
          <BrowserRouter>
            <SentryRoutes>
              <Route
                path="/"
                element={
                  <MainPageComponent
                    configuration={configuration}
                    menuItems={menus}
                    orderEnable={orderEnable}
                  />
                }
              />
              {orderEnable && cartMenus && (
                <Route
                  path="/cart"
                  element={
                    <ShopCartWrapper
                      menuItems={cartMenus}
                      configuration={configuration}
                    />
                  }
                />
              )}
              <Route path="/login" element={<LoginComponent />} />
              <Route
                path="/admin"
                element={<AdminWrapper configuration={configuration} />}
              />
              <Route path="*" element={<Navigate to="/" />} />
            </SentryRoutes>
          </BrowserRouter>
        </InternationalTranslationProvider>
      </ReactKeycloakProvider>
    </StrictMode>
  );
};

export default Content;
