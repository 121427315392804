import React from 'react';

type Props = {
  header: JSX.Element;
  main: JSX.Element;
  footer: JSX.Element;
  children?: React.ReactNode;
};

const HeaderMainFooterLayout = ({ header, main, footer }: Props) => {
  return (
    <div className="content">
      {header}
      {main}
      {footer}
    </div>
  );
};
export default HeaderMainFooterLayout;
