import {
  deleteAllDocuments,
  getCart,
  getCategories,
  getConfiguration,
  getEmailConfiguration,
  getMenus,
  sendConfiguration,
  sendDocument,
  sendEmailConfiguration,
} from './ApiService';
import DataCenter from '../data/DataCenter';
import { map } from 'lodash';

const loadInfo = () => {
  return getConfiguration().then((res: any) => {
    return res.data
      ? {
          id: res.data.id.id,
          announcementDe: res.data.announcementDe,
          announcementEn: res.data.announcementEn,
          announcementZn: res.data.announcementZn,
          telephone: res.data.telephone,
          workHours: map(res.data.workHours, (it: WorkHour) => {
            return {
              id: it.id,
              dayStart: it.dayStart,
              dayEnd: it.dayEnd,
              startTime: it.startTime,
              endTime: it.endTime,
            };
          }),
          emailDisable: res.data.emailDisable,
        }
      : DataCenter.DEFAULT_CONFIGURATION;
  });
};

const loadMenus = () => {
  return getMenus().then((res: any) => res.data);
};

const loadCategories = () => {
  return getCategories().then((res: any) => res.data);
};

const uploadDocument = (data: FormData, token?: string) => {
  sendDocument(data, token).then((res: { statusText: any }) =>
    console.log(res.statusText)
  );
};

const clearAllFiles = (token?: string) => {
  deleteAllDocuments(token).then((res: any) => console.log(res));
};

const updateManagementInfo = (management: Management, token?: string) => {
  sendConfiguration(management, token).then((res: any) => console.log(res));
};

const loadEmailConfiguration = (token?: string) => {
  return getEmailConfiguration(token).then((res: any) => res.data);
};

const updateEmailConfiguration = (
  emailConfiguration: EmailConfiguration,
  token?: string
) => {
  sendEmailConfiguration(emailConfiguration, token).then((res: any) =>
    console.log(res)
  );
};

const getAllCarts = (token?: string) => {
  return getCart(token);
};

export {
  loadInfo,
  loadMenus,
  loadCategories,
  uploadDocument,
  clearAllFiles,
  updateManagementInfo,
  loadEmailConfiguration,
  updateEmailConfiguration,
  getAllCarts,
};
