import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { map } from 'lodash';

type StateProp = {
  fields: WorkHour;
  errors: {
    startTime: boolean;
    endTime: boolean;
  };
};

type Props = {
  workHours: WorkHour[];
  removeWorkHours: (index: number) => void;
  addWorkHours: (workHour: WorkHour) => void;
};

class WorkHourSelectComponent extends Component<Props, StateProp> {
  constructor(props: Props) {
    super(props);
    this.state = {
      fields: {
        dayStart: 'monday',
        dayEnd: 'monday',
        startTime: '',
        endTime: '',
      },
      errors: {
        startTime: true,
        endTime: true,
      },
    };
  }

  removeWorkHours = (index: number) => {
    this.props.removeWorkHours(index);
  };

  addWorkHours = () => {
    this.props.addWorkHours(this.state.fields);
  };

  handleChange = (name: string, event: any) => {
    const { value } = event.target;
    const fields: any = this.state.fields;
    fields[name] = value;
    const errors = this.handleValidation();
    this.setState({
      fields: fields,
      errors: errors,
    });
  };

  handleValidation() {
    const fields = this.state.fields;
    const errors: any = {};

    const regExpRule = '(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$';
    //startTime
    if (!fields.startTime) {
      errors.startTime = true;
    } else {
      errors.startTime = !fields.startTime.match(regExpRule);
    }

    //endTime
    if (!fields.endTime) {
      errors.endTime = true;
    } else {
      errors.endTime = !fields.endTime.match(regExpRule);
    }

    return errors;
  }

  invalid = () => {
    return (
      this.state.errors &&
      (this.state.errors.startTime || this.state.errors.endTime)
    );
  };

  render() {
    const { workHours } = this.props;

    const TableBody = (props: any) => {
      const rows = map(props.workHours, (it: WorkHour, index: number) => {
        return (
          <tr key={index} className="white right-text">
            <td>
              <p key={it.id}>
                {' '}
                &nbsp;
                <FormattedMessage id={'app.home.openHours.' + it.dayStart} />
                &nbsp;
                <FormattedMessage id="app.home.openHours.to" />
                &nbsp;
                <FormattedMessage id={'app.home.openHours.' + it.dayEnd} />
                &nbsp;: {it.startTime} - {it.endTime}
              </p>
            </td>
            <td>
              <button
                className="btn btn-primary"
                onClick={() => props.removeWorkHours(index)}
              >
                <span>删除</span>
              </button>
            </td>
          </tr>
        );
      });

      return <tbody>{rows}</tbody>;
    };

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <form>
              <div className="row">
                <label className="col-md-4">开始日期</label>
                <select
                  value={this.state.fields.dayStart}
                  className="custom-select"
                  onChange={this.handleChange.bind(this, 'dayStart')}
                >
                  <option value="monday">星期一</option>
                  <option value="tuesday">星期二</option>
                  <option value="wednesday">星期三</option>
                  <option value="thursday">星期四</option>
                  <option value="friday">星期五</option>
                  <option value="saturday">星期六</option>
                  <option value="sunday">星期日</option>
                </select>
              </div>
              <div className="row">
                <label className="col-md-4">结束日期</label>
                <select
                  value={this.state.fields.dayEnd}
                  className="custom-select"
                  onChange={this.handleChange.bind(this, 'dayEnd')}
                >
                  <option value="monday">星期一</option>
                  <option value="tuesday">星期二</option>
                  <option value="wednesday">星期三</option>
                  <option value="thursday">星期四</option>
                  <option value="friday">星期五</option>
                  <option value="saturday">星期六</option>
                  <option value="sunday">星期日</option>
                </select>
              </div>
              <div className="row">
                <label className="col-md-4">开始时间</label>
                <input
                  type="text"
                  className="col-md-4"
                  placeholder="HH:MM:SS"
                  onChange={this.handleChange.bind(this, 'startTime')}
                />
              </div>
              <div className="row">
                <label className="col-md-4">结束时间</label>
                <input
                  type="text"
                  className="col-md-4"
                  placeholder="HH:MM:SS"
                  pattern="[0-23]:[0-59]:[0-59]"
                  onChange={this.handleChange.bind(this, 'endTime')}
                />
              </div>
              <div className="row col-md-4">
                <input
                  type="button"
                  className="btn btn-success"
                  disabled={this.invalid()}
                  onClick={this.addWorkHours}
                  value="添加"
                />
              </div>
            </form>
          </div>
          <div className="col-md-6">
            <table>
              <TableBody
                workHours={workHours}
                removeWorkHours={this.removeWorkHours}
              />
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default WorkHourSelectComponent;
