import React from 'react';

const FooterComponent = () => {
  return (
    <footer className="sub_footer">
      <div className="container">
        <div className="col-md-4">
          <p className="sub-footer-text text-center">
            &copy; Copy Right Restaurant 2019
          </p>
        </div>
        <div className="col-md-4" />
        <div className="col-md-4">
          <p className="sub-footer-text text-center">
            <span>Built With Care By Xiang</span>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
