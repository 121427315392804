import NavigationComponent from './NavigationComponent';
import FooterComponent from './FooterComponent';
import React, { useEffect, useState } from 'react';
import CartService from '../service/CartService';
import HeaderMainFooterLayout from '../base/HeaderMainFooterLayout';
import HomeComponent from './HomeComponent';
import SpecialityComponent from './SpecialityComponent';
import MediaComponent from './MediaComponent';
import DiscountComponent from './DiscountComponent';
import ContactComponent from './ContactComponent';
import DataCenter from '../data/DataCenter';
import { some } from 'lodash';

type Props = {
  menuItems: MenuItem[];
  configuration: Configuration;
  orderEnable: boolean;
};

const MainPageComponent = ({
  menuItems,
  configuration,
  orderEnable,
}: Props) => {
  const [cartCount, setCartCount] = useState(0);

  useEffect(() => {
    setCartCount(CartService.loadCart().length);
  }, [setCartCount]);

  const updateCart = () => {
    const orders = CartService.loadCart();
    setCartCount(orders.length);
  };

  const hasMenu = (items: MenuItem[], menuKey: string) => {
    return some(items, (it: MenuItem) => it.key === menuKey && it.show);
  };

  return (
    <HeaderMainFooterLayout
      header={
        <NavigationComponent menuItems={menuItems} cartCount={cartCount} />
      }
      main={
        <div className="content">
          {hasMenu(menuItems, DataCenter.HOME_MENU) && (
            <HomeComponent configuration={configuration} />
          )}
          {hasMenu(menuItems, DataCenter.SPECIALITY_MENU) && (
            <SpecialityComponent
              updateCart={updateCart}
              orderEnable={orderEnable}
            />
          )}
          {hasMenu(menuItems, DataCenter.MEDIA_MENU) && <MediaComponent />}
          {hasMenu(menuItems, DataCenter.DISCOUNT_MENU) && (
            <DiscountComponent />
          )}
          {hasMenu(menuItems, DataCenter.CONTACT_MENU) && (
            <ContactComponent
              telephone={configuration.telephone}
              emailDisable={configuration.emailDisable}
            />
          )}
        </div>
      }
      footer={<FooterComponent />}
    />
  );
};

export default MainPageComponent;
