import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import InputField from '../base/InputField';
import { sendEmail } from '../service/ApiService';

type Props = {
  telephone: string;
  emailDisable: boolean;
};

const ContactComponent = ({ telephone, emailDisable }: Props) => {
  const [fields, setFields] = useState<Email>({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const [errors, setErrors] = useState<{ [key: string]: string | undefined }>({
    initialise: 'Empty',
  });

  const handleValidation = () => {
    const errors: { [key: string]: string } = {};
    //Name
    if (!fields['name']) {
      errors.name = 'Not Empty';
    }

    //Email
    if (!fields['email']) {
      errors.email = 'Not Empty';
    } else {
      const lastAtPos = fields['email'].lastIndexOf('@');
      const lastDotPos = fields['email'].lastIndexOf('.');

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields['email'].indexOf('@@') === -1 &&
          lastDotPos > 2 &&
          fields['email'].length - lastDotPos > 2
        )
      ) {
        errors.email = 'Invalid Email';
      }
    }

    //Message
    if (!fields['message']) {
      errors.message = 'Not Empty';
    }
    return errors;
  };

  const handleFieldValueChange = (field: string, value: any) => {
    const checkFields: any = fields;
    checkFields[field] = value;
    setFields(checkFields);
    const errors = handleValidation();
    setErrors(errors);
  };

  const submitEmail = (event: any) => {
    event.preventDefault();
    const email: Email = fields;
    sendEmail(email).catch((e: any) => console.log(e));
  };

  const isEmailValid = () => {
    return !!Object.keys(errors).length;
  };

  return (
    <section id="contact">
      <div className="social_connect">
        <div className="text-content container">
          <div className="col-md-6">
            <span className="social_heading">
              <FormattedMessage id="app.contact.follow" />
            </span>
            <ul className="social_icons">
              <li>
                <span className="icon-twitter color_animation" />
              </li>
              <li>
                <span className="icon-github color_animation" />
              </li>
              <li>
                <span className="icon-linkedin color_animation" />
              </li>
              <li>
                <span className="icon-mail color_animation" />
              </li>
            </ul>
          </div>
          <div className="col-md-4">
            <span className="social_heading">
              <FormattedMessage id="app.contact" />
            </span>
            <span className="social_info">
              <a className="color_animation" href={'tel:' + telephone + ''}>
                {telephone}
              </a>
            </span>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="inner">
              <div className="contact-form contact">
                <form id="contact-us" onSubmit={submitEmail}>
                  <div className="col-md-6 ">
                    <InputField
                      item={{
                        id: 'app.contact.name',
                        type: 'text',
                        defaultMessage: 'Name',
                        field: 'name',
                        className: 'form',
                        error: errors.name,
                      }}
                      onChange={handleFieldValueChange}
                    />
                    <InputField
                      item={{
                        id: 'app.contact.email',
                        type: 'text',
                        defaultMessage: 'Email',
                        field: 'email',
                        className: 'form',
                        error: errors.email,
                      }}
                      onChange={handleFieldValueChange}
                    />
                    <InputField
                      item={{
                        id: 'app.contact.subject',
                        type: 'text',
                        defaultMessage: 'Subject',
                        field: 'subject',
                        className: 'form',
                      }}
                      onChange={handleFieldValueChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormattedMessage
                      id="app.contact.message"
                      defaultMessage="Thema"
                    >
                      {(placeholder) => (
                        <textarea
                          name="message"
                          className="form textarea"
                          onChange={(event) =>
                            handleFieldValueChange(
                              'message',
                              event.target.value
                            )
                          }
                          value={fields['message']}
                          placeholder={placeholder.toString()}
                          style={{
                            borderColor: errors.message ? 'red' : '',
                          }}
                        />
                      )}
                    </FormattedMessage>
                  </div>
                  <div className="relative fullwidth col-xs-12">
                    <button
                      type="submit"
                      style={{
                        color: isEmailValid() || emailDisable ? '#808080' : '',
                      }}
                      disabled={isEmailValid() || emailDisable}
                      data-toggle="modal"
                      data-target="#orderPopup"
                      className="form-btn"
                    >
                      <FormattedMessage id="app.contact.send" />
                    </button>
                  </div>
                  <div className="clear" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="orderPopup"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="orderPopupLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h3>
                <FormattedMessage id="app.contact.confirm.title" />
              </h3>
            </div>
            <div className="modal-body">
              <p>
                <FormattedMessage id="app.contact.confirm.message" />
              </p>
              <div>
                <p>{<span>{fields.message}</span>}</p>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
              >
                <FormattedMessage id="app.contact.closeButton" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactComponent;
