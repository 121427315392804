import React, { ReactNode, useMemo, useState } from 'react';
import { IntlProvider } from 'react-intl';
import de from './modules/translation/de.json';
import en from './modules/translation/en.json';
import zn from './modules/translation/zn.json';
import DataCenter from './modules/data/DataCenter';

const Context = React.createContext<InternationalStateProp>({});

const InternationalTranslationProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const languageSetting = localStorage.getItem(DataCenter.LANGUAGE_KEY);
  const language = languageSetting
    ? languageSetting
    : navigator.language.split(/[-_]/)[0];

  const [local, setLocal] = useState<string>(language);
  const handleLanguageMessage = (language: string) => {
    switch (language) {
      case DataCenter.LANGUAGE.EN:
        return en;
      case DataCenter.LANGUAGE.ZH:
        return zn;
      default:
        return de;
    }
  };

  const switchToEnglish = () => {
    localStorage.setItem(DataCenter.LANGUAGE_KEY, DataCenter.LANGUAGE.EN);
    setLocal(DataCenter.LANGUAGE.EN);
  };

  const switchToGerman = () => {
    localStorage.setItem(DataCenter.LANGUAGE_KEY, DataCenter.LANGUAGE.DE);
    setLocal(DataCenter.LANGUAGE.DE);
  };

  const switchToChinese = () => {
    localStorage.setItem(DataCenter.LANGUAGE_KEY, DataCenter.LANGUAGE.ZH);
    setLocal(DataCenter.LANGUAGE.ZH);
  };

  const value = useMemo(
    () => ({
      locale: local,
      messages: handleLanguageMessage(local),
      switchToEnglish: switchToEnglish,
      switchToGerman: switchToGerman,
      switchToChinese: switchToChinese,
    }),
    [local]
  );

  return (
    <Context.Provider value={value}>
      <IntlProvider
        key={value.locale}
        locale={value.locale}
        messages={value.messages}
        defaultLocale={DataCenter.LANGUAGE.DE}
      >
        {children}
      </IntlProvider>
    </Context.Provider>
  );
};

export { InternationalTranslationProvider, Context as InternationalContext };
