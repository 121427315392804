import {
  clearAllFiles,
  getAllCarts,
  loadEmailConfiguration,
  updateEmailConfiguration,
  updateManagementInfo,
  uploadDocument,
} from '../../service/UserService';
import { map } from 'lodash';
import { Collapse } from 'react-collapse';
import CartService from '../../service/CartService';
import { Link, useNavigate } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import WorkHourSelectComponent from '../WorkHourSelectComponent';
import InputField from '../../base/InputField';
import { InternationalContext } from '../../../InternationalContext';
import { useKeycloak } from '@react-keycloak/web';
import { v4 as uuidv4 } from 'uuid';

type AdminProps = {
  initialConfiguration: Configuration;
};

export const Admin = ({ initialConfiguration }: AdminProps) => {
  const { keycloak } = useKeycloak();
  const { locale } = useContext(InternationalContext);
  const navigate = useNavigate();

  const [configuration, setConfiguration] = useState<Configuration>({
    ...initialConfiguration,
  });
  const [emailConfiguration, setEmailConfiguration] =
    useState<EmailConfiguration>({
      id: uuidv4(),
      host: '',
      port: 0,
      username: '',
      password: '',
      senderEmail: '',
      isPrimary: true,
    });
  const [isProfileSelected, setIsProfileSelected] = useState(true);
  const [isAnnouncementSelected, setIsAnnouncementSelected] = useState(false);
  const [isWorkHoursSelected, setIsWorkHoursSelected] = useState(false);
  const [isEmailConfigurationSelected, setIsEmailConfigurationSelected] =
    useState(false);
  const [isOrderSelected, setIsOrderSelected] = useState(false);
  const [shopCarts, setShopCarts] = useState<Cart[]>([]);

  useEffect(() => {
    loadEmailConfiguration(keycloak.token).then((data: any) => {
      let configuration: EmailConfiguration = { ...emailConfiguration };
      if (data) {
        configuration = {
          id: data.id.id,
          host: data.host,
          port: data.port,
          username: data.username,
          password: data.password,
          senderEmail: data.senderEmail,
          isPrimary: true,
        };
      }
      setEmailConfiguration(configuration);
    });
  }, [emailConfiguration, keycloak.token]);

  const handleChange = (field: string, e: any) => {
    const currentConfiguration: any = { ...configuration };
    currentConfiguration[field] = e.target.value;
    setConfiguration(currentConfiguration);
  };

  const handleEmailConfigurationPrimaryEmailCheckChange = (
    field: string,
    event: any
  ) => {
    handleEmailConfigurationValueChange(field, event.target.checked);
  };

  const handleEmailConfigurationValueChange = (
    field: string,
    value: string | number
  ) => {
    const currentEmailConfiguration: any = { ...emailConfiguration };
    currentEmailConfiguration[field] = value;
    setEmailConfiguration(currentEmailConfiguration);
  };

  const handleCheckChange = (field: string, e: any) => {
    const currentConfiguration: any = { ...configuration };
    currentConfiguration[field] = e.target.checked;
    setConfiguration(currentConfiguration);
  };

  const uploadFileDocument = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const data = new FormData();
      data.append('file', event.target.files[0]);
      uploadDocument(data, keycloak.token);
    }
  };

  const clearFiles = () => clearAllFiles(keycloak.token);

  const updateAdminManagementInfo = (event: any) => {
    event.preventDefault();
    const management: Management = {
      adminSection: {
        id: configuration.id,
        announcementDe: configuration.announcementDe,
        announcementEn: configuration.announcementEn,
        announcementZn: configuration.announcementZn,
        telephone: configuration.telephone,
        emailDisable: configuration.emailDisable,
        updateBy: keycloak.profile?.username,
      },
      workHours: configuration.workHours,
    };
    updateManagementInfo(management, keycloak.token);
    if (emailConfiguration.host) {
      updateEmailConfiguration(emailConfiguration, keycloak.token);
    }
  };

  const goToHomePage = () => {
    navigate(`/`);
  };

  const removeWorkHours = (index: number) => {
    const { workHours } = configuration;
    workHours.splice(index, 1);
    setConfiguration({ ...configuration });
  };

  const addWorkHours = (newWorkHour: WorkHour) => {
    const { workHours } = configuration;
    workHours.push({ ...newWorkHour });
    setConfiguration({ ...configuration });
  };

  const selectProfile = () => {
    setIsProfileSelected(true);
    setIsAnnouncementSelected(false);
    setIsWorkHoursSelected(false);
    setIsEmailConfigurationSelected(false);
    setIsOrderSelected(false);
  };

  const selectAnnouncement = () => {
    setIsAnnouncementSelected(true);
    setIsProfileSelected(false);
    setIsWorkHoursSelected(false);
    setIsEmailConfigurationSelected(false);
    setIsOrderSelected(false);
  };

  const selectWorkHours = () => {
    setIsWorkHoursSelected(true);
    setIsProfileSelected(false);
    setIsAnnouncementSelected(false);
    setIsEmailConfigurationSelected(false);
    setIsOrderSelected(false);
  };

  const selectEmailConfiguration = () => {
    setIsEmailConfigurationSelected(true);
    setIsProfileSelected(false);
    setIsWorkHoursSelected(false);
    setIsAnnouncementSelected(false);
    setIsOrderSelected(false);
  };

  const selectOrder = () => {
    setIsOrderSelected(true);
    setIsEmailConfigurationSelected(false);
    setIsProfileSelected(false);
    setIsWorkHoursSelected(false);
    setIsAnnouncementSelected(false);

    getAllCarts(keycloak.token).then((result: { data: Cart[] }) =>
      setShopCarts([...result.data])
    );
  };

  const collapseCart = (cart: Cart) => {
    cart.collapsed = !cart.collapsed;
  };

  const carts = map(shopCarts, (it: Cart, key: number) => {
    const detailOrders = map(it.orders, (order: OrderItem) => {
      return (
        <tr key={order.id}>
          <th>{order.id}</th>
          <td>{order.nameZn}</td>
          <td>{order.price}</td>
          <td>{order.quantity}</td>
          <td>{order.total}</td>
        </tr>
      );
    });
    const collapsedRow = it.collapsed && (
      <tr>
        <td colSpan={13}>
          <Collapse isOpened={true}>
            <div className="panel panel-info">
              <div className="panel-heading">详细订单列表</div>
              <div className="panel-body">
                <table className="table">
                  <thead>
                    <tr>
                      <th>#id</th>
                      <th>菜单</th>
                      <th>单价</th>
                      <th>数量</th>
                      <th>总价</th>
                    </tr>
                  </thead>
                  <tbody>{detailOrders}</tbody>
                </table>
              </div>
            </div>
          </Collapse>
        </td>
      </tr>
    );

    const oneRow = (
      <tr key={key}>
        <td>{key + 1}</td>
        <td>{it.orderReference}</td>
        <td>{it.customer.name}</td>
        <td>{it.customer.email}</td>
        <td>{it.customer.address}</td>
        <td>{it.customer.telephone}</td>
        <td>
          <p className="message">{it.customer.message}</p>
        </td>
        <td>{CartService.formatCurrency(it.price, locale)}</td>
        <td>{CartService.formatCurrency(it.deliveryFee, locale)}</td>
        <td>{CartService.formatCurrency(it.total, locale)}</td>
        <td>{it.orderAt}</td>
        <td>
          <input type="checkbox" checked={it.isEmailRequired} disabled />
        </td>
        <td>
          <input type="checkbox" checked={it.isEmailSent} disabled />
        </td>
        <td>
          <Link to="#" className="badge" onClick={() => collapseCart(it)}>
            {it.orders.length}
          </Link>
        </td>
      </tr>
    );

    return [oneRow, collapsedRow];
  });

  return (
    <section id="admin">
      <nav className="navbar navbar-inverse navbar-fixed-top">
        <div className="container-fluid">
          <div className="navbar-header">
            <Link className="navbar-brand" to="#">
              后台管理系统
            </Link>
          </div>
          <div id="navbar" className="navbar-collapse collapse">
            <ul className="nav navbar-nav navbar-right">
              <li>
                <Link to="#">{keycloak.idTokenParsed?.preferred_username}</Link>
              </li>
              <li>
                <Link to="#" onClick={() => keycloak.logout()}>
                  退出登录
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="container-fluid main">
        <div className="row">
          <div className="col-sm-2 col-md-2 sidebar">
            <ul className="nav nav-sidebar">
              <li>
                <Link to="#" onClick={() => selectProfile()}>
                  用户信息
                </Link>
              </li>
              <li>
                <Link to="#" onClick={() => selectAnnouncement()}>
                  公告设置
                </Link>
              </li>
              <li>
                <Link to="#" onClick={() => selectWorkHours()}>
                  工作时间
                </Link>
              </li>
              <li>
                <Link to="#" onClick={() => selectEmailConfiguration()}>
                  邮箱设置
                </Link>
              </li>
              <li>
                <Link to="#" onClick={() => selectOrder()}>
                  订单详情
                </Link>
              </li>
              <li>
                <div className="btn-group refresh" role="group">
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={updateAdminManagementInfo}
                    data-toggle="modal"
                    data-target="#updateInfo"
                  >
                    <span>更新</span>
                  </button>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-sm-9">
            {isProfileSelected && (
              <div className="container-fluid">
                <div className="row">
                  <h3>用户信息</h3>
                  <h4>当前用户：{keycloak.tokenParsed?.name}</h4>
                  <h4>邮箱：{keycloak.tokenParsed?.email}</h4>
                  <h4>
                    Realm权限：
                    {keycloak.tokenParsed?.realm_access?.roles.join(', ')}
                  </h4>
                  <h4>
                    Resource权限：
                    {keycloak.tokenParsed?.resource_access?.account.roles.join(
                      ', '
                    )}
                  </h4>
                  <h4>Scope：{keycloak.tokenParsed?.scope}</h4>
                </div>
                <div className="row">
                  <h4>联系方式</h4>
                  <div className="row col-md-4">
                    <input
                      type="text"
                      className="form"
                      onChange={(event) => handleChange('telephone', event)}
                      value={configuration.telephone ?? ''}
                    />
                  </div>
                </div>
                <div className="row">
                  <h4>上传文件</h4>
                  <div className="row col-md-4">
                    <input
                      type="file"
                      className="form"
                      name="file"
                      onChange={uploadFileDocument}
                    />
                  </div>
                  <div className="col-md-1">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={clearFiles}
                    >
                      清除文件
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="input-group">
                    <span className="nput-group-btn">
                      <input
                        type="checkbox"
                        checked={configuration.emailDisable}
                        onChange={(event) =>
                          handleCheckChange('emailDisable', event)
                        }
                      />
                    </span>
                    <span>关闭邮件发送功能</span>
                  </div>
                </div>
              </div>
            )}
            {isAnnouncementSelected && (
              <div className="container-fluid">
                <form id="contact-us">
                  <div className="row">
                    <div className="col-md-4">
                      <span className="social_heading">德语更新公告</span>
                      <textarea
                        className="form textarea"
                        onChange={(event) =>
                          handleChange('announcementDe', event)
                        }
                        value={configuration.announcementDe ?? ''}
                      />
                    </div>
                    <div className="col-md-4">
                      <span className="social_heading">英文更新公告</span>
                      <textarea
                        className="form textarea"
                        onChange={(event) =>
                          handleChange('announcementEn', event)
                        }
                        value={configuration.announcementEn ?? ''}
                      />
                    </div>
                    <div className="col-md-4">
                      <span className="social_heading">中文更新公告</span>
                      <textarea
                        className="form textarea"
                        onChange={(event) =>
                          handleChange('announcementZn', event)
                        }
                        value={configuration.announcementZn ?? ''}
                      />
                    </div>
                  </div>
                </form>
              </div>
            )}
            {isWorkHoursSelected && (
              <div className="container-fluid">
                <div className="row">
                  <div className="right-text">
                    <span className="">营业时间</span>
                    <WorkHourSelectComponent
                      workHours={configuration.workHours}
                      removeWorkHours={removeWorkHours}
                      addWorkHours={addWorkHours}
                    />
                  </div>
                </div>
              </div>
            )}
            {isEmailConfigurationSelected && (
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6" style={{ margin: '30px 0' }}>
                    <InputField
                      item={{
                        id: 'app.emailConfiguration.host',
                        type: 'text',
                        defaultMessage: '主机host地址',
                        field: 'host',
                        value: emailConfiguration.host,
                        className: 'form',
                      }}
                      onChange={handleEmailConfigurationValueChange}
                    />
                    <InputField
                      item={{
                        id: 'app.emailConfiguration.port',
                        type: 'number',
                        defaultMessage: 'port端口如1234',
                        field: 'port',
                        value: emailConfiguration.port,
                        className: 'form',
                      }}
                      onChange={handleEmailConfigurationValueChange}
                    />
                    <InputField
                      item={{
                        id: 'app.emailConfiguration.username',
                        type: 'text',
                        defaultMessage: '用户名username',
                        field: 'username',
                        value: emailConfiguration.username,
                        className: 'form',
                      }}
                      onChange={handleEmailConfigurationValueChange}
                    />
                    <InputField
                      item={{
                        id: 'app.emailConfiguration.password',
                        type: 'text',
                        defaultMessage: '加密密码password',
                        field: 'password',
                        value: emailConfiguration.password,
                        className: 'form',
                      }}
                      onChange={handleEmailConfigurationValueChange}
                    />
                    <InputField
                      item={{
                        id: 'app.emailConfiguration.senderEmail',
                        type: 'text',
                        defaultMessage: '发送者邮箱如xxx@email.com',
                        field: 'senderEmail',
                        value: emailConfiguration.senderEmail,
                        className: 'form',
                      }}
                      onChange={handleEmailConfigurationValueChange}
                    />
                    <div className="input-group">
                      <span className="nput-group-btn">
                        <input
                          type="checkbox"
                          checked={emailConfiguration.isPrimary}
                          onChange={(event) =>
                            handleEmailConfigurationPrimaryEmailCheckChange(
                              'isPrimary',
                              event
                            )
                          }
                        />
                      </span>
                      <span>是否为主邮件</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {isOrderSelected && (
              <div>
                <h2 className="page-header">订单概况</h2>
                <div id="order-details" className="row">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>#id</th>
                          <th>订单号</th>
                          <th>客人</th>
                          <th>邮件</th>
                          <th>地址</th>
                          <th>电话</th>
                          <th>客人留言</th>
                          <th>单价</th>
                          <th>运费</th>
                          <th>总价</th>
                          <th>订单时间</th>
                          <th>邮件确认</th>
                          <th>已发送</th>
                          <th>详情</th>
                        </tr>
                      </thead>
                      <tbody>{carts}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="updateInfo"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="updateInfoLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header" />
            <div className="modal-body">
              <p>信息已经更新，请转到主页查询</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={goToHomePage}
              >
                网站主页
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
